import LayoutA7 from "../../components/layout-a7"
import React from "react"
import "../themeA1/enroll-info-list.css"
import EnrollRacesList from '../../components/enroll-races-list'


export default class A7EnrollInfoList extends React.Component {
  state = {
    redirect:false
  }



  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA7/profile/enrollInfo"

    return(
      <LayoutA7 >
        <EnrollRacesList color={color} src={src} />
      </LayoutA7>
    )
  }
}